@font-face {
	font-family: 'SofiaPro';
	font-weight: 100;
	src: url('SofiaProUltraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 100;
	font-style: italic;
	src: url('SofiaProUltraLightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 200;
	src: url('SofiaProExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 200;
	font-style: italic;
	src: url('SofiaProExtraLightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 300;
	src: url('SofiaProLight.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 300;
	font-style: italic;
	src: url('SofiaProLightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 400;
	src: url('SofiaProRegular.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 400;
	font-style: italic;
	src: url('SofiaProRegularItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 500;
	src: url('SofiaProMedium.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 500;
	font-style: italic;
	src: url('SofiaProMediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 600;
	src: url('SofiaProSemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 600;
	font-style: italic;
	src: url('SofiaProSemiBoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 700;
	src: url('SofiaProBold.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 700;
	font-style: italic;
	src: url('SofiaProBoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 800;
	src: url('SofiaProBlack.ttf') format('truetype');
}

@font-face {
	font-family: 'SofiaPro';
	font-weight: 800;
	font-style: italic;
	src: url('SofiaProBlackItalic.ttf') format('truetype');
}
