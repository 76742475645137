@import '../assets/fonts/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--color-primary: #e11f1c;
	}
}

:root .employee {
	--color-primary: #1b692f;
}

.nav {
	@apply w-full
    items-center 
    h-16 
    fixed 
    top-0 
    z-30 
    bg-white 
    font-bold 
    text-lg
    text-left 
    lg:text-base 
    lg:h-20
    lg:flex;
}

.nav-menu {
	@apply max-h-0
    overflow-hidden 
    transition-all
    ease-in-out
    duration-200
    uppercase
    mb-0
    px-16
    bg-white
    lg:px-0
    lg:max-h-full
    lg:h-full
    lg:flex
    xl:pr-5
    xl:pl-8
    lg:items-center;
}

.footer {
	@apply grid
    justify-center
    items-center
    bg-white
    pb-6
    mt-8
    md:grid-cols-3
    md:px-4;
}

.btn {
	@apply block
    p-3
    rounded-lg
    cursor-pointer
    text-center;
}

.btn-primary {
	@apply btn
    bg-primary
    text-white;
}

.btn-secondary {
	@apply btn
    border
    border-primary
    bg-white
    text-primary;
}

.form-input {
	@apply my-3
    px-2
    w-fit
    mx-auto;
}

.form-label {
	@apply block w-full
    mx-2
    mb-2;
}

.input-wrapper {
	@apply flex
    justify-between
    items-center
    border
    border-primary
    h-10
    w-80
    rounded-lg
    pl-2
    pr-3;
}

.input {
	@apply w-full
    h-full
    text-lg
    mr-2
    focus-visible:outline-none;
}

.input-icon {
	@apply max-h-full
    w-7;
}

.error {
	@apply text-red-app
    mx-2
    mt-2;
}

.card {
	@apply border-[0.3px]
    border-[#0000000d]
    bg-white
    rounded-2xl
    shadow-md
    py-3;
}

.brand-card {
	@apply card
    w-[95px]
    h-[155px]
    py-3
    flex
    flex-col
    relative
    items-center
    justify-center;
}

.swiper-pagination .swiper-pagination-bullet {
	@apply w-3
    h-3
    bg-white
    border
    border-primary
    opacity-100;
}

.swiper-pagination .swiper-pagination-bullet-active {
	@apply bg-primary;
}

.button-prev:after {
	content: 'prev';
}

.button-next:after {
	content: 'next';
}
.button-prev:after,
.button-next:after {
	font-family: swiper-icons;
	@apply text-primary text-5xl;
}

.sumasix-terms {
	@apply px-8;
}

.sumasix-terms h1 {
	@apply m-0
    text-2xl
    text-[#444444];
}

.sumasix-terms p {
	@apply mt-1 text-xl text-left text-[#222222] leading-7 font-normal;
}

.switch {
	@apply bg-[#D8D8D8]
    relative
    inline-flex
    h-7
    w-14
    flex-shrink-0
    cursor-pointer
    rounded-full
    border-2
    border-transparent
    transition-colors
    duration-200
    ease-in-out;
}

.switch-status {
	@apply translate-x-0
    pointer-events-none
    inline-block
    h-6
    w-6
    transform
    rounded-full
    bg-primary
    shadow
    ring-0
    transition
    duration-200
    ease-in-out;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition:
		background-color 0s 600000s,
		color 0s 600000s !important;
}

@media (min-width: 768px) {
	.swiper-pagination .swiper-pagination-bullet {
		@apply w-4
        h-4
        !mx-2;
	}
}
